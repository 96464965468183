
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "faq-component",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Frequently Asked Questions");
    });
  },
  created() {
    //Set page title
    document.title =
      "Frequently Asked Questions | " + process.env.VUE_APP_TITLE;
  }
});
